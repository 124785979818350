import { Form, Input } from "antd";
import React from "react";

const RegisterForm = (props) => {
  function onFinish(values) {
    props.registerUser(values);
  }
  return (
    <Form id="registerForm" labelCol={{ span: 8 }} wrapperCol={{ span: 10 }} layout="horizontal" onFinish={(values) => onFinish(values)} size="small">
      <Form.Item label="First Name" name="first_name" rules={[{ required: true }]}>
        <Input placeholder="First Name" />
      </Form.Item>
      <Form.Item label="Last Name" name="last_name" rules={[{ required: true }]}>
        <Input placeholder="Last Name" />
      </Form.Item>
      <Form.Item label="Business Name" name="business_name" rules={[{ required: false }]}>
        <Input placeholder="Business Name" />
      </Form.Item>
      <Form.Item label="E-Mail Address" name="email_address" rules={[{ required: true }]}>
        <Input placeholder="Email Address" />
      </Form.Item>
      <Form.Item label="Password" name="password" rules={[{ required: true }]}>
        <Input.Password placeholder="Password" />
      </Form.Item>
      <Form.Item label="Password Verification" name="confirm_password" rules={[{ required: true }]}>
        <Input.Password placeholder="Password" />
      </Form.Item>
    </Form>
  );
};

export default RegisterForm;
