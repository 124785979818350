import { Form, Input } from "antd";
import React from "react";

const SaveUserTagForm = (props) => {
  function onFinish(values) {
    props.saveUserTags(values);
  }
  return (
    <Form
      id="saveUserTagForm"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 10 }}
      layout="horizontal"
      onFinish={(values) => onFinish(values)}
      size="small"
    >
      <Form.Item label="Title" name="title" rules={[{ required: true }]}>
        <Input placeholder="Title" />
      </Form.Item>
    </Form>
  );
};

export default SaveUserTagForm;
