import React from "react";
import logo from "./resources/dove_io_logo_new.png";
import LoginForm from "./components/Forms/LoginForm";
import SaveUserTagForm from "./components/Forms/SaveUserTagForm";
import RegisterForm from "./components/Forms/RegisterForm";
import Plot from "react-plotly.js";
import "antd/dist/antd.dark.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import _ from "lodash";
import "moment-timezone";
import moment from "moment";
import WebcamWidget from "./components/WebcamWidget";
import "video-react/dist/video-react.css"; // import css
import Tour from "reactour";
import { Player } from "video-react";
import ReactWordcloud from "react-wordcloud";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import {
  Typography,
  Row,
  Modal,
  Input,
  Col,
  Layout,
  Button,
  Spin,
  Card,
  AutoComplete,
  Tooltip,
  Menu,
  Progress,
  Select,
  Dropdown,
  Checkbox,
  message,
  Table,
  Avatar,
  Tag,
  Upload,
} from "antd";

import {
  UserOutlined,
  CheckCircleFilled,
  BulbOutlined,
  CloseCircleFilled,
  QuestionCircleOutlined,
  CloudDownloadOutlined,
  CameraOutlined,
  PlusOutlined,
  InboxOutlined,
} from "@ant-design/icons";
const steps = [
  {
    selector: ".tour-1",
    content: (
      <span style={{ color: "black" }}>
        "Users can upload data through the web application for processing."
      </span>
    ),
  },
  {
    selector: ".tour-3",
    content: (
      <span style={{ color: "black" }}>
        "Data that is ingested will show in the data table, allowing a user to
        further dive into the metadata generated about the particular type of
        data. Users can further investigate the data by pressing the plus sign
        next to a data object."
      </span>
    ),
  },
  {
    selector: ".tour-4",
    content: (
      <span style={{ color: "black" }}>
        "Users have the ability to group system tags together to form a user tag
        group. This allows users to quickly find data that you care about."
      </span>
    ),
  },
  {
    selector: ".tour-2",
    content: (
      <span style={{ color: "black" }}>
        "Dove IO provides an easy to use, powerful, and reliable system to
        process and distribute data automatically. This allows data to be
        ingested into the system automatically from various dataflow sources
        outside of the web interface framework."
      </span>
    ),
  },
  {
    selector: ".tour-5",
    content: (
      <span style={{ color: "black" }}>
        "Users have the ability to enable a web camera attached to the computer,
        and process this feed to the cloud for automatic analysis."
      </span>
    ),
  },
];

const { Dragger } = Upload;

const api =
  "https://pnxqa0ka3b.execute-api.us-east-1.amazonaws.com/production/api_platform";
const { Text, Link } = Typography;

const { Meta } = Card;
const { Header, Footer, Content } = Layout;

class App extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      user_info: {},
      authorized: false,
      check_progress: false,
      data: [],
      search_keyword: "",
      user_tag_dialog: false,
      dataflow_status: [],
      expanded_table: false,
      ngram_bar_summary_traces: [],
      offset: 0,
      result_count: 0,
      size: 10,
      timeline_summary_traces: [],
      loading: false,
      tag_filters: [],
      visualization_dialog: false,
      file_type_filter: {},
      tour: false,
      ingest_sources: [],
      webcam_enabled: false,
      file_types: [],
      saved_user_tags: [],
      ingest_sources_filter: {},
      webcam_service_enabled: false,
      player: {},
      currentTime: 0,
      timeline_traces: [],
      ngram_bar_traces: [],
      fromtimestamp: moment().subtract(30, "days").valueOf(),
      totimestamp: moment().valueOf(),
      userlogindialog: false,
      job_progress: { progress: 100, errors: [] },
      userregisterdialog: false,
    };
    this.login = this.login.bind(this);
    this.registerUser = this.registerUser.bind(this);
    this.addFilterTags = this.addFilterTags.bind(this);
    this.onFilterFileTypes = this.onFilterFileTypes.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleSearchTableChange = this.handleSearchTableChange.bind(this);
    this.removeTagFilter = this.removeTagFilter.bind(this);
    this.setExpanded = this.setExpanded.bind(this);
    this.onFilterDataflowCategory = this.onFilterDataflowCategory.bind(this);
    this.downloadS3Object = this.downloadS3Object.bind(this);
    this.checkProgress = this.checkProgress.bind(this);
    this.getMetadata = this.getMetadata.bind(this);
    this.saveUserTags = this.saveUserTags.bind(this);
    this.deleteUserTags = this.deleteUserTags.bind(this);
    this.onVideoTimelineHover = this.onVideoTimelineHover.bind(this);
    this.getDataflow = this.getDataflow.bind(this);
    this.search = _.debounce(this.search.bind(this), 500);
    this.deleteElasticSearchData = this.deleteElasticSearchData.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleToggleUserDialog = this.handleToggleUserDialog.bind(this);
    this.processUpload = this.processUpload.bind(this);
    this.handleToggleRegisterDialog =
      this.handleToggleRegisterDialog.bind(this);
    this.handleSetCustomTimeRange = this.handleSetCustomTimeRange.bind(this);
    this.toggleWebcamService = this.toggleWebcamService.bind(this);
    this.cameraChecker = this.cameraChecker.bind(this);
  }

  cameraChecker() {
    if (this.state.webcam_service_enabled) {
      console.log("Camera Processing");
      const imageSrc = this.state.camera_reference.getScreenshot({
        width: 1920,
        height: 1080,
      });

      var arr = imageSrc.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      const imageFile = new File([u8arr], "camera_output.png", { type: mime });
      this.processUpload(imageFile);
    }
  }
  toggleWebcamService(ref) {
    this.setState({
      camera_reference: ref,
      webcam_service_enabled: !this.state.webcam_service_enabled,
    });
  }
  setExpanded(expanded_table) {
    this.setState({ expanded_table });
  }

  handleSearchTableChange(pagination, filters, sorter) {
    let offset = pagination.current * pagination.pageSize - pagination.pageSize;
    let size = pagination.pageSize;

    this.setState({ offset, size }, function () {
      this.refreshData();
    });
  }

  addFilterTags(record) {
    let tags = [];

    if (record.fileType.startsWith("video")) {
      let image_metadata = this.getMetadata(record);
      let video_metadata = image_metadata.video_metadata;
      tags = video_metadata
        .map((item) => item.tag)
        .filter((value, index, self) => self.indexOf(value) === index);
    } else if (record.fileType.startsWith("image")) {
      let image_metadata = this.getMetadata(record);
      let metadata = image_metadata.metadata;

      metadata.forEach(function (m) {
        tags.push(m.tag);
      });
    } else {
      record.ngram_freq.forEach(function (m) {
        tags.push(m);
      });
    }
    let currentTags = this.state.tag_filters;

    tags.forEach(function (t) {
      if (t !== "") {
        if (!currentTags.includes(t)) {
          currentTags.push(t.toLowerCase());
        }
      }
    }, this);

    this.setState(
      { tag_filters: currentTags, search_keyword: "", offset: 0, size: 10 },
      function () {
        this.refreshData();
      }
    );
  }
  addFilterTag(tag) {
    if (tag !== "") {
      let tags = this.state.tag_filters;
      if (!tags.includes(tag.toLowerCase())) {
        tags.push(tag.toLowerCase());
      }
      this.setState(
        { tag_filters: tags, search_keyword: "", offset: 0, size: 10 },
        function () {
          this.refreshData();
        }
      );
    }
  }

  removeTagFilter(tag) {
    let tags = [];

    this.state.tag_filters.forEach(function (t) {
      console.log(t, tag);
      if (t.toLowerCase() !== tag.toLowerCase()) {
        tags.push(t);
      }
    });

    this.setState({ tag_filters: tags, offset: 0, size: 10 }, function () {
      this.refreshData();
    });
  }

  handleToggleRegisterDialog() {
    this.setState({ userlogindialog: false });
    this.setState({ userregisterdialog: !this.state.userregisterdialog });
  }

  handleToggleUserDialog() {
    this.setState({ userlogindialog: !this.state.userlogindialog });
  }

  handleSetCustomTimeRange(value) {
    if (value === null || value.length === 0) {
      this.setState({
        fromtimestamp: moment().subtract(30, "days").valueOf(),
        totimestamp: moment().valueOf(),
      });
    } else {
      const fromtimestamp = value[0].tz(this.state.timezone).valueOf();
      const totimestamp = value[1].tz(this.state.timezone).valueOf();

      this.setState(
        {
          fromtimestamp: fromtimestamp,
          totimestamp: totimestamp,
        },
        function () {
          this.refreshData();
        }
      );
    }
  }

  handleChange(timeperiod) {
    this.setState(
      {
        fromtimestamp: moment().subtract(timeperiod, "hours").valueOf(),
        totimestamp: moment().valueOf(),
      },
      function () {
        this.refreshData();
      }
    );
  }

  checkProgress() {
    if (this.state.check_progress === true) {
      let opts = {};
      opts["api"] = "getJobProgress";
      opts["username"] = localStorage.getItem("username");
      opts["token"] = localStorage.getItem("token");
      fetch(api, {
        method: "post",
        body: JSON.stringify(opts),
      })
        .then((data) => data.json())
        .then((data) => {
          let job_progress = data.message.data;
          this.setState({
            job_progress,
          });

          if (job_progress.progress === 100) {
            this.refreshData();
            this.setState({ check_progress: false });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }

  componentDidMount() {
    let timezone_display = moment.tz.zone(moment.tz.guess()).abbr(new Date());
    let timezone = moment.tz.guess();
    this.setState({ timezone_display, timezone });
    this.refreshData();
    this.getDataflow();
    this.jobInterval = setInterval(() => this.checkProgress(), 1000);
    this.refreshInterval = setInterval(() => {
      if (this.state.offset === 0 && this.state.expanded_table === false) {
        this.refreshData();
      }
    }, 10000);
    this.playerInterval = setInterval(() => this.videoChecker(), 1000);
    this.cameraInterval = setInterval(() => this.cameraChecker(), 5000);
    this.dataflowStatusInterval = setInterval(() => this.getDataflow(), 120000);
  }

  videoChecker() {
    if (this.player) {
      const { player } = this.player.getState();
      this.setState({
        player,
        currentTime: player.currentTime,
      });
    }
  }

  handleStateChange(state, prevState) {
    // copy player state to this component's state
    this.setState({
      player: state,
      currentTime: state.currentTime,
    });
  }
  onFilterFileTypes(e, value) {
    let file_type_filter = this.state.file_type_filter;
    if (e.target.checked === true) {
      file_type_filter[value] = "";
    } else {
      if (file_type_filter.hasOwnProperty(value)) {
        delete file_type_filter[value];
      }
    }
    this.setState({ file_type_filter, offset: 0, size: 10 }, function () {
      this.refreshData();
    });
  }

  onFilterDataflowCategory(e, value) {
    let ingest_sources_filter = this.state.ingest_sources_filter;
    if (e.target.checked === true) {
      ingest_sources_filter[value] = "";
    } else {
      if (ingest_sources_filter.hasOwnProperty(value)) {
        delete ingest_sources_filter[value];
      }
    }
    this.setState({ ingest_sources_filter, offset: 0, size: 10 }, function () {
      this.refreshData();
    });
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
    clearInterval(this.playerInterval);
    clearInterval(this.cameraInterval);
    clearInterval(this.jobInterval);
    clearInterval(this.dataflowStatusInterval);
    clearInterval(this.refreshInterval);
  }

  downloadS3Object(url) {
    window.open(url);
  }

  processUpload = (file) => {
    let percent = 0;
    this.setState({ percent_upload: Number(percent.toFixed(0)) });
    let opts = {};

    opts["filetype"] = file.type;
    opts["filesize"] = file.size;
    opts["last_modified"] = file.lastModified;
    opts["filename"] = file.name;
    opts["token"] = localStorage.getItem("token");
    opts["username"] = localStorage.getItem("username");
    opts["api"] = "uploadS3Object";

    fetch(api, {
      method: "post",
      body: JSON.stringify(opts),
    })
      .then((data) => data.json())
      .then((data) => {
        let options = {
          "Content-Type": file.type,
        };
        fetch(data.message.data, {
          method: "put",
          body: file,
          headers: options,
        })
          .then(() => {
            this.setState({ check_progress: true });
          })
          .catch((e) => {
            this.setState({ check_progress: false });
          });
      })
      .catch((e) => {
        this.setState({ check_progress: false });
        console.error(e);
      });

    return false;
  };

  search(keyword) {
    this.setState(
      { search_keyword: keyword, offset: 0, size: 10 },
      function () {
        this.refreshData();
      }
    );
  }

  saveUserTags(values) {
    let opts = {};
    opts["tag_filters"] = this.state.tag_filters;
    opts["title"] = values.title;
    opts["api"] = "save_tag_filter";
    opts["username"] = localStorage.getItem("username");
    opts["token"] = localStorage.getItem("token");
    console.log(opts);
    fetch(api, {
      method: "post",
      body: JSON.stringify(opts),
    })
      .then((data) => data.json())
      .then((data) => {
        this.setState({ user_tag_dialog: false });
        this.refreshData();
        message.info("Successfuly Saved User Tag!");
      })
      .catch((e) => {
        message.info("Error Saving User Tag!");
        console.error(e);
      });
  }

  deleteElasticSearchData(id, index) {
    let opts = {};
    opts["id"] = id;
    opts["index"] = index;
    opts["api"] = "delete_es_id";
    opts["username"] = localStorage.getItem("username");
    opts["token"] = localStorage.getItem("token");
    fetch(api, {
      method: "post",
      body: JSON.stringify(opts),
    })
      .then((data) => data.json())
      .then((data) => {
        this.refreshData();
        message.info("Successfuly Deleted Document!");
      })
      .catch((e) => {
        message.info("Error Deleting Document!");
        console.error(e);
      });
  }

  deleteUserTags(id) {
    let opts = {};
    opts["id"] = id;
    opts["api"] = "delete_tag_filter";
    opts["username"] = localStorage.getItem("username");
    opts["token"] = localStorage.getItem("token");
    fetch(api, {
      method: "post",
      body: JSON.stringify(opts),
    })
      .then((data) => data.json())
      .then((data) => {
        this.setState({ user_tag_dialog: false });
        this.refreshData();
        message.info("Successfuly Deleted User Tag!");
      })
      .catch((e) => {
        message.info("Error Deleting User Tag!");
        console.error(e);
      });
  }

  login(values) {
    let opts = {};
    opts["token"] = "";
    opts["username"] = values.email_address;
    opts["password"] = values.password;
    opts["api"] = "loginAccount";
    fetch(api, {
      method: "post",
      body: JSON.stringify(opts),
    })
      .then((data) => data.json())
      .then((data) => {
        localStorage.setItem("token", data.message.token);
        localStorage.setItem("username", data.message.data.email_address);
        this.setState({
          user_info: data.message.user,
          authorized: true,
        });
        message.info("Successfuly Logged In!");
      })
      .then(() => {
        this.refreshData();
      })
      .then(() => this.handleToggleUserDialog())
      .catch((e) => {
        message.error("Error Logging In!");
        console.error("ERROR", e);
      });
  }

  getMetadata(record) {
    let markers = [];
    let metadata = [];
    let video_metadata = [];
    let currentVideoTags = [];

    if (
      record.ppe_labels &&
      record.ppe_labels.persons &&
      record.ppe_labels.persons.length > 0
    ) {
      record.ppe_labels.persons.forEach(function (p) {
        p.bodyParts.forEach(function (i) {
          if (i.equipmentDetections && i.equipmentDetections.length > 0) {
            i.equipmentDetections.forEach(function (instance) {
              let boundingBox = instance.boundingBox;
              if (instance.confidence > 90) {
                metadata.push({
                  name: instance.type,
                  value: i.name,
                  confidence: instance.confidence,
                  include_tag: true,
                  tag: instance.type,
                  key:
                    instance.type +
                    instance.confidence +
                    boundingBox.top +
                    boundingBox.left,
                });

                markers.push({
                  top: boundingBox.top * 100,
                  left: boundingBox.left * 100,
                  confidence: instance.confidence,
                  name: instance.type,
                  category: "image_labels",
                });
              }
            });
          }
        });
      });
    }

    if (record.image_labels) {
      record.image_labels.labels.forEach(function (i) {
        if (i.instances && i.instances.length > 0) {
          i.instances.forEach(function (instance) {
            let boundingBox = instance.boundingBox;
            if (i.confidence > 90) {
              if (i.name !== "Person") {
                metadata.push({
                  name: i.name,
                  value: "Detected",
                  confidence: i.confidence,
                  include_tag: true,
                  tag: i.name,
                  key:
                    i.name + i.confidence + boundingBox.top + boundingBox.left,
                });

                markers.push({
                  top: boundingBox.top * 100,
                  left: boundingBox.left * 100,
                  confidence: i.confidence,
                  name: i.name,
                  category: "image_labels",
                });
              }
            }
          });
        } else {
          metadata.push({
            name: i.name,
            value: "Detected",
            confidence: i.confidence,
            include_tag: true,
            tag: i.name,
            key: i.name + i.confidence,
          });
        }
      });
    }

    if (
      record.image_text &&
      record.image_text.textDetections &&
      record.image_text.textDetections.length > 0
    ) {
      record.image_text.textDetections.forEach(function (t) {
        if (t.geometry && t.geometry.boundingBox) {
          if (t.confidence > 90) {
            metadata.push({
              name: "Text " + t.type,
              value: t.detectedText,
              confidence: t.confidence,
              include_tag: false,
              tag: t.detectedText,
              key:
                "Text " +
                t.type +
                t.confidence +
                t.detectedText +
                t.geometry.boundingBox.left +
                t.geometry.boundingBox.top,
            });
          }
        }
      });
    }
    if (record.video_labels && record.video_labels.length > 0) {
      record.video_labels.forEach(function (f) {
        video_metadata.push({
          name: f.label,
          value: f.timestamp,
          confidence: f.confidence,
          include_tag: true,
          tag: f.label,
          key: "Video " + f.label + f.confidence + f.timestamp,
        });
      });
    }
    let videoTags = [];
    video_metadata.forEach(function (v) {
      const seconds = v.value / 1000;
      const currentTime = this.state.currentTime;
      if (seconds > currentTime - 1 && seconds < currentTime + 1) {
        videoTags.push(v.name);
      }
    }, this);

    const uniqueTags = [...new Set(videoTags)];
    uniqueTags.forEach(function (t) {
      currentVideoTags.push(
        <Tag key={t} color="#0cc675" style={{ margin: "10px" }}>
          {t}
        </Tag>
      );
    });

    if (
      record.image_faces &&
      record.image_faces.faceDetails &&
      record.image_faces.faceDetails.length > 0
    ) {
      let personCount = 1;
      record.image_faces.faceDetails.forEach(function (f) {
        if (f.confidence > 90) {
          if (f.boundingBox) {
            metadata.push({
              name: "Person " + personCount,
              value: "Detected",
              confidence: f.confidence,
              include_tag: true,
              tag: "Person " + personCount,
              key: "Person " + personCount + f.confidence,
            });

            markers.push({
              top: f.boundingBox.top * 100,
              left: f.boundingBox.left * 100,
              confidence: f.confidence,
              name: "Person " + personCount,
              category: "image_labels",
            });

            if (f.ageRange) {
              let average = (ageRange) =>
                ageRange.reduce((a, b) => a + b) / ageRange.length;
              let avgAge = average([f.ageRange.low, f.ageRange.high]);

              metadata.push({
                name: "Person " + personCount + " - Age",
                value: avgAge,
                confidence: f.confidence,
                include_tag: false,
                tag: "",
                key: "Person " + personCount + "Age" + f.confidence,
              });
            }
            if (f.gender.confidence > 50) {
              metadata.push({
                name: "Person " + personCount + " - Gender",
                value: f.gender.value,
                confidence: f.gender.confidence,
                include_tag: false,
                tag: "",
                key: "Person " + personCount + "Gender" + f.gender.confidence,
              });
            }

            if (f.beard.confidence > 50) {
              metadata.push({
                name: "Person " + personCount + " - Beard",
                value: f.beard.value,
                confidence: f.beard.confidence,
                include_tag: false,
                tag: "",
                key: "Person " + personCount + "Beard" + f.beard.confidence,
              });
            }
            if (f.eyeglasses.confidence > 50) {
              metadata.push({
                name: "Person " + personCount + " - Eye Glasses",
                value: f.eyeglasses.value,
                confidence: f.eyeglasses.confidence,
                include_tag: false,
                tag: "",
                key:
                  "Person " +
                  personCount +
                  "EyeGlasses" +
                  f.eyeglasses.confidence,
              });
            }
            if (f.mouthOpen.confidence > 50) {
              metadata.push({
                name: "Person " + personCount + " - Mouth Open",
                value: f.mouthOpen.value,
                confidence: f.mouthOpen.confidence,
                include_tag: false,
                tag: "",
                key:
                  "Person " +
                  personCount +
                  "MouthOpen" +
                  f.mouthOpen.confidence,
              });
            }
            if (f.smile.confidence > 50) {
              metadata.push({
                name: "Person " + personCount + " - Smile",
                value: f.smile.value,
                confidence: f.smile.confidence,
                include_tag: false,
                tag: "",
                key: "Person " + personCount + "Smile" + f.smile.confidence,
              });
            }
            if (f.sunglasses.confidence > 50) {
              metadata.push({
                name: "Person " + personCount + " - Sun Glasses",
                value: f.sunglasses.value,
                confidence: f.sunglasses.confidence,
                include_tag: false,
                tag: "",
                key:
                  "Person " +
                  personCount +
                  "SunGlasses" +
                  f.sunglasses.confidence,
              });
            }
            if (f.mustache.confidence > 50) {
              metadata.push({
                name: "Person " + personCount + " - Mustache",
                value: f.mustache.value,
                confidence: f.mustache.confidence,
                include_tag: false,
                tag: "",
                key:
                  "Person " + personCount + "Mustache" + f.mustache.confidence,
              });
            }
            if (f.emotions && f.emotions.length > 0) {
              metadata.push({
                name: "Person " + personCount + " - Emotion",
                value: f.emotions[0].type,
                confidence: f.emotions[0].confidence,
                include_tag: true,
                tag: f.emotions[0].type,
                key:
                  "Person " +
                  personCount +
                  "Emotion" +
                  f.emotions[0].type +
                  f.emotions[0].confidence,
              });
            }

            if (f.eyesOpen.confidence > 50) {
              if (f.eyesOpen.value === true) {
                metadata.push({
                  name: "Person " + personCount + " - Eyes",
                  value: "Opened",
                  confidence: f.eyesOpen.confidence,
                  include_tag: false,
                  tag: "",
                  key:
                    "Person " +
                    personCount +
                    "EyesOpen" +
                    f.eyesOpen.confidence,
                });
              }
              if (f.eyesOpen.value === false) {
                metadata.push({
                  name: "Person " + personCount + " - Eyes",
                  value: "Closed",
                  confidence: f.eyesOpen.confidence,
                  include_tag: false,
                  tag: "",
                  key:
                    "Person " +
                    personCount +
                    "EyesClosed" +
                    f.eyesOpen.confidence,
                });
              }
            }
            personCount++;
          }
        }
      });
    }

    let result = {
      currentVideoTags: currentVideoTags,
      markers: markers,
      metadata: metadata,
      video_metadata: video_metadata,
    };
    return result;
  }

  registerUser(values) {
    let opts = {};
    opts = values;
    opts["api"] = "registerAccount";

    if (values.password === values.confirm_password) {
      fetch(api, {
        method: "post",
        body: JSON.stringify(opts),
      })
        .then((data) => data.json())
        .then((data) => {
          message.info("Successfully Registered!");
          localStorage.setItem("token", data.message.token);
          localStorage.setItem("username", data.message.data.email_address);
        })
        .then(() => {
          this.refreshData();
        })
        .then(() => this.handleToggleRegisterDialog())
        .catch((e) => {
          localStorage.removeItem("token");
          localStorage.removeItem("username");
          this.setState({
            user_info: {},
          });
          message.error("Error During Registration! " + e);
          console.error("ERROR", e);
        });
    } else {
      message.error("Your Password Does Not Match!");
    }
  }

  getDataflow() {
    let opts = {};
    opts["api"] = "dataflow_status";
    opts["username"] = localStorage.getItem("username");
    opts["token"] = localStorage.getItem("token");
    fetch(api, {
      method: "post",
      body: JSON.stringify(opts),
    })
      .then((data) => data.json())
      .then((data) => {
        if (
          data.message.data &&
          data.message.data.connections &&
          data.message.data.connections.length > 0
        ) {
          let dataflow_status = [];
          data.message.data.connections.forEach(function (c) {
            let dataflow = {};
            if (
              c.component &&
              c.component.source &&
              c.component.destination &&
              c.status &&
              c.status.aggregateSnapshot
            ) {
              dataflow.active =
                c.component.source.running && c.component.destination.running;
              dataflow.source = c.component.source.name;
              dataflow.dest = c.component.destination.name;
              dataflow.bytesIn = c.status.aggregateSnapshot.bytesIn;
              dataflow.bytesOut = c.status.aggregateSnapshot.bytesOut;
              dataflow.flowFilesIn = c.status.aggregateSnapshot.flowFilesIn;
              dataflow.flowFilesOut = c.status.aggregateSnapshot.flowFilesOut;
              dataflow.flowFilesQueued =
                c.status.aggregateSnapshot.flowFilesQueued;
              dataflow.key = c.id;
              dataflow_status.push(dataflow);
            }
          });

          this.setState({ dataflow_status: dataflow_status.reverse() });
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  refreshData() {
    this.setState({ loading: true });
    let opts = {};
    console.log(this.state.tag_filters);
    console.log(this.state.search_keyword);
    opts["api"] = "search";
    opts["from"] = this.state.offset;
    opts["size"] = this.state.size;
    opts["id"] = "";
    opts["tag_filters"] = this.state.tag_filters;
    opts["ingest_sources_filter"] = Object.keys(
      this.state.ingest_sources_filter
    );
    opts["file_type_filter"] = Object.keys(this.state.file_type_filter);
    opts["keyword"] = this.state.search_keyword;
    opts["username"] = localStorage.getItem("username");
    opts["token"] = localStorage.getItem("token");
    fetch(api, {
      method: "post",
      body: JSON.stringify(opts),
    })
      .then((data) => data.json())
      .then((data) => {
        console.log("Search", data);

        if (data.message.token === null) {
          localStorage.removeItem("token");
          localStorage.removeItem("username");
          this.setState({
            user_info: {},
            authorized: false,
          });
        } else {
          this.setState({
            user_info: data.message.user,
            authorized: true,
          });
        }

        let suggestions = [];
        if (
          data.message.data.suggestions &&
          data.message.data.suggestions.length > 0
        ) {
          data.message.data.suggestions.forEach(function (s) {
            suggestions.push({ value: s });
          });
        }
        let ngram_bar_traces = [];
        if (data.message.data.ngrams && data.message.data.ngrams.length > 0) {
          data.message.data.ngrams.forEach(function (f) {
            ngram_bar_traces.push({ text: f.key, value: f.count });
          });
        }

        let timeline_traces = [];

        if (
          data.message.data.hourly_data &&
          data.message.data.hourly_data.length > 0
        ) {
          let hourly_data = data.message.data.hourly_data;
          let format = "MMM DD h a";
          let xArray = hourly_data.map((a) => new moment(a.key).format(format));
          let yArray = hourly_data.map((a) => a.count);

          let hourly_trace = {
            x: xArray,
            y: yArray,
            type: "bar",
          };

          timeline_traces.push(hourly_trace);
        }
        this.setState({
          result_count: data.message.data.result_count,
          data: data.message.data.results,
          highlights: data.message.data.highlights,
          suggestions: suggestions,
          ngram_bar_traces: ngram_bar_traces,
          timeline_traces: timeline_traces,
          user_info: data.message.user,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({ loading: false });
        console.error(e);
      });

    opts["api"] = "get_tag_filters";
    opts["username"] = localStorage.getItem("username");
    opts["token"] = localStorage.getItem("token");
    fetch(api, {
      method: "post",
      body: JSON.stringify(opts),
    })
      .then((data) => data.json())
      .then((data) => {
        this.setState({ saved_user_tags: data.message.data });
      })
      .catch((e) => {
        console.error(e);
      });

    opts["api"] = "summary";
    opts["username"] = localStorage.getItem("username");
    opts["token"] = localStorage.getItem("token");
    fetch(api, {
      method: "post",
      body: JSON.stringify(opts),
    })
      .then((data) => data.json())
      .then((data) => {
        let ngram_bar_traces = [];
        if (data.message.data.ngrams && data.message.data.ngrams.length > 0) {
          data.message.data.ngrams.forEach(function (f) {
            ngram_bar_traces.push({ text: f.key, value: f.count });
          });
        }

        let timeline_traces = [];

        if (
          data.message.data.daily_data &&
          data.message.data.daily_data.length > 0
        ) {
          let daily_data = data.message.data.daily_data;
          // let format = "MMM DD h a";
          let xArray = daily_data.map((a) => a.key);
          let yArray = daily_data.map((a) => a.count);

          let daily_trace = {
            x: xArray,
            y: yArray,
            line: { color: "#17BECF" },
            fill: "tozeroy",
            type: "scatter",
          };

          timeline_traces.push(daily_trace);
        }

        console.log("Summary", data);
        this.setState({
          ngram_bar_summary_traces: ngram_bar_traces,
          timeline_summary_traces: timeline_traces,
          ingest_sources: data.message.data.ingest_sources,
          file_types: data.message.data.file_types,
        });
      })
      .catch((e) => {
        console.error(e);
      });
  }
  handleLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    this.setState({ authorized: false });
    this.refreshData();
  }
  onVideoTimelineHover(m, e) {
    this.player.pause();
    this.player.seek(m.value / 1000);
  }

  render() {
    function millisToMinutesAndSeconds(millis) {
      var minutes = Math.floor(millis / 60000);
      var seconds = ((millis % 60000) / 1000).toFixed(0);
      return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
    }
    const configuploader = {
      name: "file",
      multiple: true,
      showUploadList: false,
      beforeUpload: this.processUpload,
    };

    return (
      <Layout>
        <Header style={{ paddingLeft: "1%", paddingRight: "1%" }}>
          <div style={{ float: "left" }}>
            <img
              src={logo}
              alt="logo"
              style={{ height: "50px", verticalAlign: "center" }}
            />
          </div>
          <div style={{ float: "right" }}>
            {this.state.authorized ? (
              <div>
                {this.state.loading ? (
                  <Spin size="large" style={{ marginRight: "10px" }} />
                ) : (
                  ""
                )}
                <Text style={{ marginRight: "10px", color: "white" }}>
                  Welcome {this.state.user_info.full_name}!
                </Text>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        key="1"
                        icon={<UserOutlined />}
                        onClick={this.handleLogout}
                      >
                        Logout
                      </Menu.Item>
                    </Menu>
                  }
                  placement="bottomCenter"
                >
                  <Avatar
                    style={{ marginRight: "10px", backgroundColor: "#87d068" }}
                    icon={<UserOutlined />}
                  />
                </Dropdown>

                <Tooltip title="Tour Features">
                  <Button
                    onClick={() => this.setState({ tour: true })}
                    type="primary"
                    icon={<QuestionCircleOutlined />}
                  >
                    Tour
                  </Button>
                </Tooltip>
              </div>
            ) : (
              <div>
                <Button
                  style={{
                    marginRight: "10px",
                  }}
                  onClick={this.handleToggleUserDialog}
                  type="primary"
                  icon={<BulbOutlined />}
                >
                  Customer Login
                </Button>
                <Tooltip title="Tour Features">
                  <Button
                    onClick={() => this.setState({ tour: true })}
                    type="primary"
                    icon={<QuestionCircleOutlined />}
                  >
                    Tour
                  </Button>
                </Tooltip>
              </div>
            )}
          </div>
        </Header>
        <Content
          style={{
            paddingLeft: "2%",
            paddingRight: "2%",
            paddingTop: "25px",
          }}
        >
          <Row gutter={[8, 8]}>
            <Col span={16} xs={24} md={24} sm={24} lg={18}>
              <Col span={24} xs={24} md={24} sm={24} lg={24}>
                <Card style={{ width: "100%" }}>
                  <Meta
                    description={
                      <div className="tour-3">
                        <span>
                          {/*
                          <RangePicker
                            value={[moment(this.state.fromtimestamp).tz(this.state.timezone), moment(this.state.totimestamp).tz(this.state.timezone)]}
                            showTime={{
                              format: "hh:mm A",
                              defaultValue: [moment("00:00 AM", "HH:mm A"), moment("11:59 PM", "HH:mm A")],
                            }}
                            style={{ margin: "5px" }}
                            format="YYYY/MM/DD hh:mm A"
                            placeholder={["Start Time", "End Time"]}
                            onChange={this.handleSetCustomTimeRange}
                            renderExtraFooter={() => (
                              <span>
                                <Tag onClick={() => this.handleChange(24)} style={{ marginRight: "5px" }}>
                                  24h
                                </Tag>

                                <Tag onClick={() => this.handleChange(72)} style={{ marginRight: "5px" }}>
                                  3d
                                </Tag>

                                <Tag onClick={() => this.handleChange(168)} style={{ marginRight: "5px" }}>
                                  7d
                                </Tag>

                                <Tag onClick={() => this.handleChange(720)} style={{ marginRight: "5px" }}>
                                  30d
                                </Tag>

                                <Tag onClick={this.resetTimeSettings} style={{ marginRight: "5px" }}>
                                  Clear
                                </Tag>
                              </span>
                            )}
                            />*/}

                          <Select
                            style={{ width: 300, margin: "5px" }}
                            placeholder="Filters"
                            dropdownRender={() => (
                              <div>
                                <Menu>
                                  {this.state.ingest_sources.map((source) => {
                                    return (
                                      <Menu.Item
                                        key={"ingest_source_" + source}
                                      >
                                        <Checkbox
                                          style={{ marginRight: "10px" }}
                                          checked={this.state.ingest_sources_filter.hasOwnProperty(
                                            source
                                          )}
                                          onChange={(e) =>
                                            this.onFilterDataflowCategory(
                                              e,
                                              source
                                            )
                                          }
                                        />
                                        {source + " (Data Source)"}
                                      </Menu.Item>
                                    );
                                  })}
                                  {this.state.file_types.map((source) => {
                                    return (
                                      <Menu.Item key={"file_types_" + source}>
                                        <Checkbox
                                          style={{ marginRight: "10px" }}
                                          checked={this.state.file_type_filter.hasOwnProperty(
                                            source
                                          )}
                                          onChange={(e) =>
                                            this.onFilterFileTypes(e, source)
                                          }
                                        />
                                        {source + " (File Type)"}
                                      </Menu.Item>
                                    );
                                  })}
                                </Menu>
                              </div>
                            )}
                          ></Select>
                          <Tooltip title="Clear Filters">
                            <Button
                              style={{
                                marginLeft: "1px",
                                marginRight: "10px",
                              }}
                              onClick={() => {
                                this.setState(
                                  {
                                    file_type_filter: [],
                                    tag_filters: [],
                                    ingest_sources_filter: [],
                                    search_keyword: "",
                                    offset: 0,
                                    size: 10,
                                  },
                                  function () {
                                    this.refreshData();
                                  }
                                );
                              }}
                              icon={<CloseCircleFilled />}
                            />
                          </Tooltip>
                          <span
                            style={{
                              display: "inline-block",
                              maxWidth: "600px",
                            }}
                          >
                            {this.state.tag_filters.map((highlight) => {
                              return (
                                <Tag
                                  style={{ margin: "2px" }}
                                  key={"tag_filters" + highlight}
                                  closable
                                  onClose={(e) => {
                                    e.preventDefault();
                                    this.removeTagFilter(highlight);
                                  }}
                                >
                                  {highlight}
                                </Tag>
                              );
                            })}
                          </span>
                        </span>

                        <div style={{ float: "right" }}>
                          <span>
                            <AutoComplete
                              dropdownMatchSelectWidth={252}
                              style={{ width: 300, margin: "5px" }}
                              options={this.state.suggestions}
                              filterOption={(inputValue, option) =>
                                option.value
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              }
                              onSelect={(value) => this.addFilterTag(value)}
                              onSearch={(value) => this.search(value)}
                            >
                              <span>
                                <Input.Search
                                  placeholder="Search"
                                  enterButton
                                />
                              </span>
                            </AutoComplete>
                          </span>
                        </div>
                        <Table
                          size="small"
                          rowKey="id"
                          style={{ marginTop: "20px" }}
                          onChange={this.handleSearchTableChange}
                          pagination={{
                            defaultPageSize: 10,
                            current: this.state.offset === 0 ? 1 : null,
                            showSizeChanger: false,
                            pageSizeOptions: ["10"],
                            total: this.state.result_count,
                          }}
                          expandable={{
                            onExpandedRowsChange: (expanded) => {
                              console.log(expanded);
                              if (expanded.length > 0) {
                                this.setExpanded(true);
                              } else {
                                this.setExpanded(false);
                              }
                            },
                            expandedRowRender: (record) => {
                              if (record.fileType.startsWith("image")) {
                                let image_metadata = this.getMetadata(record);
                                let markers = image_metadata.markers;
                                let metadata = image_metadata.metadata;

                                let markerDraw = [];

                                markers.forEach(function (marker) {
                                  if (marker.category === "image_labels") {
                                    markerDraw.push(
                                      <span
                                        style={{
                                          position: "absolute",
                                          top: marker.top + "%",
                                          left: marker.left + "%",
                                        }}
                                        key={"image_labels_" + marker.name}
                                      >
                                        <Tag
                                          style={{ opacity: 0.6 }}
                                          icon={<BulbOutlined />}
                                          color="#3b5998"
                                        >
                                          {marker.name} -{" "}
                                          {marker.confidence.toFixed(2)} %
                                        </Tag>
                                      </span>
                                    );
                                  }
                                  if (marker.category === "image_text") {
                                    markerDraw.push(
                                      <span
                                        style={{
                                          position: "absolute",
                                          top: marker.top + "%",
                                          left: marker.left + "%",
                                        }}
                                        key={"image_text" + marker.name}
                                      >
                                        <Tag
                                          style={{ opacity: 0.9 }}
                                          icon={<BulbOutlined />}
                                          color="#db4437"
                                        >
                                          {marker.name} -{" "}
                                          {marker.confidence.toFixed(2)} %
                                        </Tag>
                                      </span>
                                    );
                                  }
                                });

                                let x = 0;
                                metadata.forEach(function (m) {
                                  m.key = x++;
                                });

                                return (
                                  <Row>
                                    <Col xs={24} md={24} sm={24} lg={12}>
                                      <div>
                                        <img
                                          alt=""
                                          src={record.url}
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                          }}
                                        />
                                        {markerDraw}
                                      </div>
                                    </Col>
                                    <Col xs={24} md={24} sm={24} lg={12}>
                                      <Table
                                        size="small"
                                        rowKey="key"
                                        columns={[
                                          {
                                            title: "Label",
                                            render: (record) => {
                                              return (
                                                <span
                                                  onClick={() =>
                                                    this.addFilterTag(
                                                      record.name
                                                    )
                                                  }
                                                  style={{ margin: "5px" }}
                                                  key={
                                                    record.name + "_video_tag"
                                                  }
                                                >
                                                  <Button
                                                    size="small"
                                                    style={{ margin: "5px" }}
                                                    icon={<PlusOutlined />}
                                                  >
                                                    {record.name}
                                                  </Button>
                                                </span>
                                              );
                                            },
                                          },
                                          {
                                            title: "Value",
                                            render: (record) => {
                                              if (
                                                typeof record.value ===
                                                "boolean"
                                              ) {
                                                if (record.value) {
                                                  return <span>Yes</span>;
                                                } else {
                                                  return <span>No</span>;
                                                }
                                              } else {
                                                return (
                                                  <span>{record.value}</span>
                                                );
                                              }
                                            },
                                          },
                                          {
                                            title: "Confidence",
                                            render: (record) => {
                                              return (
                                                <span>
                                                  {record.confidence.toFixed(2)}
                                                  %
                                                </span>
                                              );
                                            },
                                          },
                                        ]}
                                        dataSource={metadata}
                                      />
                                    </Col>
                                  </Row>
                                );
                              } else if (record.fileType.startsWith("video")) {
                                let image_metadata = this.getMetadata(record);
                                let metadata = image_metadata.video_metadata;
                                let currentVideoTags =
                                  image_metadata.currentVideoTags;
                                let uniqueTags = metadata
                                  .map((item) => item.tag)
                                  .filter(
                                    (value, index, self) =>
                                      self.indexOf(value) === index
                                  );

                                let filters = [];
                                uniqueTags.forEach(function (m) {
                                  let d = {};
                                  d.text = m;
                                  d.value = m;
                                  filters.push(d);
                                });
                                return (
                                  <Row>
                                    <Col xs={24} md={24} sm={24} lg={12}>
                                      <Player
                                        ref={(player) => {
                                          this.player = player;
                                        }}
                                        autoPlay
                                        playsInline
                                        src={record.url}
                                      />
                                      <div align="center">
                                        {currentVideoTags}
                                      </div>
                                    </Col>
                                    <Col xs={24} md={24} sm={24} lg={12}>
                                      <Table
                                        size="small"
                                        rowKey="key"
                                        columns={[
                                          {
                                            title: "Timestamp",
                                            render: (record) => {
                                              return (
                                                <Link
                                                  onClick={() =>
                                                    this.onVideoTimelineHover(
                                                      record
                                                    )
                                                  }
                                                >
                                                  {millisToMinutesAndSeconds(
                                                    record.value
                                                  )}
                                                </Link>
                                              );
                                            },
                                            sorter: (a, b) => {
                                              return a.value - b.value;
                                            },
                                          },
                                          {
                                            title: "Label",
                                            filters: filters,
                                            onFilter: (value, record) =>
                                              record.name.includes(value),
                                            render: (record) => {
                                              return (
                                                <span
                                                  onClick={() =>
                                                    this.addFilterTag(
                                                      record.name
                                                    )
                                                  }
                                                  style={{ margin: "5px" }}
                                                  key={
                                                    record.name + "_video_tag"
                                                  }
                                                >
                                                  <Button
                                                    size="small"
                                                    style={{ margin: "5px" }}
                                                    icon={<PlusOutlined />}
                                                  >
                                                    {record.name}
                                                  </Button>
                                                </span>
                                              );
                                            },
                                            sorter: (a, b) => {
                                              return a.name.localeCompare(
                                                b.name
                                              );
                                            },
                                          },
                                          {
                                            title: "Confidence",
                                            render: (record) => {
                                              return (
                                                <span>
                                                  {record.confidence.toFixed(2)}
                                                  %
                                                </span>
                                              );
                                            },
                                            sorter: (a, b) => {
                                              return (
                                                a.confidence - b.confidence
                                              );
                                            },
                                          },
                                        ]}
                                        dataSource={metadata}
                                      />
                                    </Col>
                                  </Row>
                                );
                              } else {
                                if (
                                  record.ngram_counts &&
                                  record.ngram_counts.length > 0
                                ) {
                                  let ngram_counts = record.ngram_counts;
                                  let words = [];
                                  ngram_counts.forEach(function (n) {
                                    words.push({ text: n.key, value: n.count });
                                  });

                                  return (
                                    <Row>
                                      <Col xs={24} md={24} sm={24} lg={24}>
                                        <div>
                                          {record.content.length < 300 ? (
                                            <div align="center">
                                              {record.content}
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                        <div>
                                          <ReactWordcloud
                                            words={words}
                                            minSize={[400, 400]}
                                            options={{
                                              fontSizes: [16, 72],
                                              rotations: 1,
                                              rotationAngles: [0],
                                            }}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  );
                                }
                              }
                            },
                          }}
                          columns={[
                            {
                              title: "",
                              render: (record) => {
                                if (record.fileType.startsWith("image")) {
                                  return (
                                    <img
                                      alt="JPG"
                                      src={require("./resources/icons/jpg.png")}
                                    />
                                  );
                                } else if (
                                  record.fileType.startsWith(
                                    "application/msword"
                                  ) ||
                                  record.fileType.startsWith(
                                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                  )
                                ) {
                                  return (
                                    <img
                                      alt="DOC"
                                      src={require("./resources/icons/doc.png")}
                                    />
                                  );
                                } else if (
                                  record.fileType.startsWith("application/pdf")
                                ) {
                                  return (
                                    <img
                                      alt="PDF"
                                      src={require("./resources/icons/pdf.png")}
                                    />
                                  );
                                } else if (
                                  record.fileType.startsWith("video")
                                ) {
                                  return (
                                    <img
                                      alt="AVI"
                                      src={require("./resources/icons/avi.png")}
                                    />
                                  );
                                } else {
                                  return (
                                    <img
                                      alt="Other"
                                      src={require("./resources/icons/_blank.png")}
                                    />
                                  );
                                }
                              },
                              sorter: (a, b) => {
                                return a.fileType.localeCompare(b.fileType);
                              },
                            },
                            {
                              width: 200,
                              title: "Date Processed",
                              render: (record) => {
                                let format = "MM-DD-YYYY h:mm:ss A";
                                let timestamp = new moment(
                                  record.timestamp
                                ).format(format);
                                return <span>{timestamp}</span>;
                              },
                              sorter: (a, b) => {
                                return a.timestamp - b.timestamp;
                              },
                            },
                            {
                              title: "Labels",
                              render: (record) => {
                                let highlights = [];
                                if (record.highlights.length > 0) {
                                  record.highlights.forEach(function (h) {
                                    highlights.push(
                                      <li
                                        style={{ margin: "10px" }}
                                        key={"highlight_text_" + h}
                                        dangerouslySetInnerHTML={{
                                          __html: h,
                                        }}
                                      ></li>
                                    );
                                  });
                                }
                                if (record.fileType.startsWith("video")) {
                                  let image_metadata = this.getMetadata(record);
                                  let video_metadata =
                                    image_metadata.video_metadata;
                                  let uniqueTags = video_metadata
                                    .map((item) => item.tag)
                                    .filter(
                                      (value, index, self) =>
                                        self.indexOf(value) === index
                                    );
                                  return (
                                    <span>
                                      {uniqueTags.map((m) => {
                                        if (
                                          this.state.tag_filters.includes(
                                            m.toLowerCase()
                                          )
                                        ) {
                                          return (
                                            <span
                                              onClick={() =>
                                                this.addFilterTag(m)
                                              }
                                              style={{ margin: "5px" }}
                                              key={m + "_video_tag"}
                                            >
                                              <Button
                                                size="small"
                                                style={{ margin: "5px" }}
                                                icon={<PlusOutlined />}
                                                color="#1976D2"
                                              >
                                                {m}
                                              </Button>
                                            </span>
                                          );
                                        } else {
                                          return (
                                            <span
                                              onClick={() =>
                                                this.addFilterTag(m)
                                              }
                                              style={{ margin: "5px" }}
                                              key={m + "_video_tag"}
                                            >
                                              <Button
                                                size="small"
                                                style={{ margin: "5px" }}
                                                icon={<PlusOutlined />}
                                              >
                                                {m}
                                              </Button>
                                            </span>
                                          );
                                        }
                                      })}
                                    </span>
                                  );
                                } else if (
                                  record.fileType.startsWith("image")
                                ) {
                                  let image_metadata = this.getMetadata(record);
                                  let metadata = image_metadata.metadata;

                                  return (
                                    <span>
                                      {metadata.map((m) => {
                                        if (m.include_tag === true) {
                                          if (
                                            this.state.tag_filters.includes(
                                              m.tag.toLowerCase()
                                            )
                                          ) {
                                            return (
                                              <span
                                                onClick={() =>
                                                  this.addFilterTag(m.tag)
                                                }
                                                style={{ margin: "5px" }}
                                                key={m.key}
                                              >
                                                <Button
                                                  size="small"
                                                  style={{ margin: "5px" }}
                                                  icon={<PlusOutlined />}
                                                  color="#1976D2"
                                                >
                                                  {m.tag}
                                                </Button>
                                              </span>
                                            );
                                          } else {
                                            return (
                                              <span
                                                onClick={() =>
                                                  this.addFilterTag(m.tag)
                                                }
                                                style={{ margin: "5px" }}
                                                key={m.key}
                                              >
                                                <Button
                                                  size="small"
                                                  style={{ margin: "5px" }}
                                                  icon={<PlusOutlined />}
                                                >
                                                  {m.tag}
                                                </Button>
                                              </span>
                                            );
                                          }
                                        } else {
                                          return <span key={m.key} />;
                                        }
                                      })}
                                    </span>
                                  );
                                } else {
                                  return (
                                    <div>
                                      <span>
                                        {record.ngram_freq.map((ngram) => {
                                          if (
                                            this.state.tag_filters.includes(
                                              ngram.toLowerCase()
                                            )
                                          ) {
                                            return (
                                              <span
                                                onClick={() =>
                                                  this.addFilterTag(ngram)
                                                }
                                                style={{ margin: "5px" }}
                                                key={ngram}
                                              >
                                                <Button
                                                  size="small"
                                                  icon={<PlusOutlined />}
                                                  color="#1976D2"
                                                >
                                                  {ngram}
                                                </Button>
                                              </span>
                                            );
                                          } else {
                                            return (
                                              <span
                                                onClick={() =>
                                                  this.addFilterTag(ngram)
                                                }
                                                style={{ margin: "5px" }}
                                                key={ngram}
                                              >
                                                <Button
                                                  size="small"
                                                  icon={<PlusOutlined />}
                                                >
                                                  {ngram}
                                                </Button>
                                              </span>
                                            );
                                          }
                                        })}
                                      </span>
                                      {highlights}
                                    </div>
                                  );
                                }
                              },
                            },
                            {
                              width: 150,
                              title: "",
                              render: (record) => {
                                return (
                                  <span>
                                    <Tooltip title="Add all filter tags to find other ingested data that is similar.">
                                      <Button
                                        style={{
                                          margin: "5px",
                                        }}
                                        shape="circle"
                                        size="small"
                                        onClick={() =>
                                          this.addFilterTags(record)
                                        }
                                        icon={<PlusOutlined />}
                                      />
                                    </Tooltip>
                                    <Tooltip title="Download Raw File">
                                      <Button
                                        style={{
                                          margin: "5px",
                                        }}
                                        shape="circle"
                                        size="small"
                                        onClick={() =>
                                          this.downloadS3Object(record.url)
                                        }
                                        icon={<CloudDownloadOutlined />}
                                      />
                                    </Tooltip>
                                    <Tooltip title="Delete Document">
                                      <Button
                                        style={{
                                          margin: "5px",
                                          color: "#db4437",
                                        }}
                                        shape="circle"
                                        size="small"
                                        disabled={!this.state.authorized}
                                        onClick={() =>
                                          this.deleteElasticSearchData(
                                            record.id,
                                            record.index
                                          )
                                        }
                                        icon={<CloseCircleFilled />}
                                      />
                                    </Tooltip>
                                  </span>
                                );
                              },
                            },
                          ]}
                          dataSource={this.state.data}
                        />
                      </div>
                    }
                  />
                </Card>
              </Col>
            </Col>
            <Col span={8} xs={24} md={24} sm={24} lg={6}>
              <Col span={24} xs={24} md={24} sm={24} lg={24}>
                <Card
                  className="tour-5"
                  title="Camera Control"
                  extra={
                    <Tooltip title="Enable Webcam">
                      <Button
                        style={{
                          margin: "5px",
                          color: this.state.webcam_enabled
                            ? "hsl(170, 40%, 60%)"
                            : "#db4437",
                        }}
                        shape="circle"
                        size="small"
                        disabled={!this.state.authorized}
                        onClick={() =>
                          this.setState({
                            webcam_enabled: !this.state.webcam_enabled,
                          })
                        }
                        icon={<CameraOutlined />}
                      />
                    </Tooltip>
                  }
                >
                  <Meta
                    description={
                      <WebcamWidget
                        toggleService={this.toggleWebcamService}
                        webcam_service_enabled={
                          this.state.webcam_service_enabled
                        }
                        webcam_enabled={this.state.webcam_enabled}
                      />
                    }
                  />
                </Card>
                <Card>
                  <Meta
                    description={
                      <div align="center" className="tour-1">
                        <Dragger
                          {...configuploader}
                          key="uploadWidget"
                          disabled={this.state.authorized ? false : true}
                        >
                          <p style={{ width: "100%", height: "50px" }}>
                            <InboxOutlined
                              style={{ fontSize: "48px", color: "#08c" }}
                            />
                          </p>
                          <p style={{ width: "100%", height: "50px" }}>
                            {this.state.authorized
                              ? "Click or drag file(s) to this area to upload."
                              : "Uploading is Disabled for Demo Users."}
                          </p>
                        </Dragger>

                        <Progress
                          percent={this.state.job_progress.progress}
                          showInfo={false}
                        />
                      </div>
                    }
                  />
                </Card>
              </Col>

              <Col span={24} xs={24} md={24} sm={24} lg={24}>
                <Card
                  title="Tag Groups"
                  extra={
                    <Button
                      onClick={() => this.setState({ user_tag_dialog: true })}
                      disabled={
                        !this.state.tag_filters.length > 0 ||
                        !this.state.authorized
                      }
                      type="primary"
                      size="small"
                      key="saveTags"
                    >
                      Save Tag Group
                    </Button>
                  }
                >
                  <Meta
                    description={
                      <div className="tour-4">
                        <Table
                          size="small"
                          rowKey="id"
                          columns={[
                            {
                              title: "Description",
                              render: (record) => {
                                return (
                                  <span
                                    onClick={() =>
                                      this.setState(
                                        { tag_filters: [] },
                                        function () {
                                          record.tag_filters.forEach(function (
                                            t
                                          ) {
                                            this.addFilterTag(t);
                                          },
                                          this);
                                        }
                                      )
                                    }
                                    key={"master_tag" + record.title}
                                  >
                                    <Button
                                      size="small"
                                      style={{ margin: "5px" }}
                                      icon={<PlusOutlined />}
                                    >
                                      {record.title}
                                    </Button>
                                  </span>
                                );
                              },
                            },

                            {
                              title: "",
                              render: (record) => {
                                return (
                                  <span>
                                    <Tooltip title="Delete Tag Group">
                                      <Button
                                        style={{
                                          margin: "5px",
                                          color: "#db4437",
                                        }}
                                        shape="circle"
                                        size="small"
                                        disabled={!this.state.authorized}
                                        onClick={() =>
                                          this.deleteUserTags(record.id)
                                        }
                                        icon={<CloseCircleFilled />}
                                      />
                                    </Tooltip>
                                  </span>
                                );
                              },
                            },
                          ]}
                          dataSource={this.state.saved_user_tags}
                        />
                      </div>
                    }
                  />
                </Card>
              </Col>

              <Col span={24} xs={24} md={24} sm={24} lg={24}>
                <Card title="Data Processed">
                  <Meta
                    description={
                      <div align="center">
                        {this.state.timeline_summary_traces.length > 0 ? (
                          <Plot
                            data={this.state.timeline_summary_traces}
                            layout={{
                              showlegend: false,
                              width: 300,
                              height: 150,
                              paper_bgcolor: "rgba(0,0,0,0)",
                              plot_bgcolor: "rgba(0,0,0,0)",
                              title: {
                                font: {
                                  family: "Roboto",
                                  size: 14,
                                },
                              },
                              font: {
                                family: "Roboto",
                                size: 10,
                              },
                              margin: { t: 10, b: 30, l: 0, r: 0 },
                              xaxis: {
                                showgrid: false,
                              },
                              yaxis: {
                                showgrid: false,
                                showline: false,
                              },
                            }}
                            config={{
                              displayModeBar: false,
                              displaylogo: false,
                              modeBarButtonsToRemove: ["lasso2d", "select2d"],
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    }
                  />
                </Card>
              </Col>
              <Col span={24} xs={24} md={24} sm={24} lg={24}>
                <Card title="Dataflow">
                  <Meta
                    description={
                      <div className="tour-2">
                        <Table
                          size="small"
                          rowKey="key"
                          columns={[
                            {
                              title: "Source",
                              render: (record) => {
                                if (record.active) {
                                  return (
                                    <span>
                                      <Tooltip
                                        title={
                                          "Queue: " + record.flowFilesQueued
                                        }
                                      >
                                        <CheckCircleFilled
                                          style={{ color: "#0f9d58" }}
                                        />{" "}
                                        {record.source}
                                      </Tooltip>
                                    </span>
                                  );
                                } else {
                                  return (
                                    <span>
                                      <Tooltip
                                        title={
                                          "Queue: " + record.flowFilesQueued
                                        }
                                      >
                                        <CloseCircleFilled
                                          style={{ color: "#db4437" }}
                                        />{" "}
                                        {record.source}
                                      </Tooltip>
                                    </span>
                                  );
                                }
                              },
                            },
                            {
                              title: "Destination",
                              render: (record) => {
                                return <span>{record.dest}</span>;
                              },
                            },
                            {
                              title: "IO",
                              render: (record) => {
                                return (
                                  <span>
                                    {record.flowFilesIn +
                                      " / " +
                                      record.flowFilesOut}
                                  </span>
                                );
                              },
                            },
                          ]}
                          dataSource={this.state.dataflow_status}
                        />
                      </div>
                    }
                  />
                </Card>
              </Col>
            </Col>
          </Row>
        </Content>
        <Footer style={{ paddingLeft: "20%", paddingRight: "20%" }}>
          <div align="center">Copyright 2022 Dove IO, LLC</div>
        </Footer>

        <Modal
          title="Login to Dove IO Platform"
          visible={this.state.userlogindialog}
          closable={false}
          footer={[
            <Button
              onClick={this.handleToggleRegisterDialog}
              type="primary"
              key="registerButton"
            >
              Register
            </Button>,
            <Button
              form="loginForm"
              type="primary"
              htmlType="submit"
              key="loginButton"
            >
              Login
            </Button>,
            <Button
              key="closeButton"
              onClick={() => this.handleToggleUserDialog()}
            >
              Close
            </Button>,
          ]}
          width={760}
        >
          <LoginForm loginUser={this.login} />
        </Modal>
        <Modal
          title="Save Current User Tags?"
          visible={this.state.user_tag_dialog}
          closable={false}
          footer={[
            <Button
              form="saveUserTagForm"
              type="primary"
              htmlType="submit"
              key="saveUserTagButton"
            >
              Save
            </Button>,
            <Button
              key="closeButton"
              onClick={() => this.setState({ user_tag_dialog: false })}
            >
              Close
            </Button>,
          ]}
          width={760}
        >
          {this.state.tag_filters.map((highlight) => {
            return (
              <Tag
                style={{ margin: "2px" }}
                key={"tag_filters_save" + highlight}
              >
                {highlight}
              </Tag>
            );
          })}
          <SaveUserTagForm saveUserTags={this.saveUserTags} />
        </Modal>

        <Modal
          title="Register for Dove IO Platform Account"
          visible={this.state.userregisterdialog}
          closable={false}
          footer={[
            <Button
              form="registerForm"
              key="save"
              type="primary"
              htmlType="submit"
            >
              Register
            </Button>,
            <Button
              key="close"
              onClick={() => this.handleToggleRegisterDialog()}
            >
              Close
            </Button>,
          ]}
          width={760}
        >
          <RegisterForm registerUser={this.registerUser} />
        </Modal>

        <Tour
          steps={steps}
          isOpen={this.state.tour}
          onRequestClose={() => this.setState({ tour: false })}
        />
      </Layout>
    );
  }
}

export default App;
