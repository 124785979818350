import Webcam from "react-webcam";
import React from "react";
import { CameraOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};

const WebcamWidget = (props) => {
  const webcamRef = React.useRef(null);

  const toggleServiceFunction = React.useCallback(
    (props) => {
      props.toggleService(webcamRef.current);
    },
    [webcamRef]
  );

  if (props.webcam_enabled === true) {
    return (
      <div align="center">
        <Webcam audio={false} height={120} ref={webcamRef} screenshotFormat="image/jpeg" width={200} videoConstraints={videoConstraints} />

        <Tooltip title="Start Camera Service">
          <Button onClick={() => toggleServiceFunction(props)} type="primary" icon={<CameraOutlined />}>
            {props.webcam_service_enabled ? "Stop Camera Processing" : "Start Camera Processing"}
          </Button>
        </Tooltip>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default WebcamWidget;
