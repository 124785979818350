import { Form, Input } from "antd";
import React from "react";

const LoginForm = (props) => {
  function onFinish(values) {
    props.loginUser(values);
  }
  return (
    <Form id="loginForm" labelCol={{ span: 8 }} wrapperCol={{ span: 10 }} layout="horizontal" onFinish={(values) => onFinish(values)} size="small">
      <Form.Item label="E-Mail Address" name="email_address" rules={[{ required: true }]}>
        <Input placeholder="Email Address" />
      </Form.Item>
      <Form.Item label="Password" name="password" rules={[{ required: true }]}>
        <Input.Password placeholder="Password" />
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
